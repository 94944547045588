import { useLazyQuery } from '@apollo/client';
import { WHOLESALE_ME_ACCOUNT_QUERY } from '../queries';
import { API_HEADERS, CLIENT_NAME } from '../constants';
import { getMarketplaceHeader } from '../utils';

export const useWholesaleGenericMe = () => {
  const [runQuery] = useLazyQuery(WHOLESALE_ME_ACCOUNT_QUERY, {});

  const fetchWholeSaleMeAccount = async (token) => {
    try {
      const res = await runQuery({
        context: {
          clientName: CLIENT_NAME.wholesale,
          headers: {
            [API_HEADERS.session]: token,
            ...getMarketplaceHeader(),
          },
        },
      });

      const data = res?.data?.me;
      if (data) {
        return data;
      }
    } catch (error) {
      console.error('Fetch account details failed: ', error);
    }
  };

  return {
    fetchWholeSaleMeAccount,
  };
};
