import React from 'react';
import clsx from 'clsx';
import { makeStyles } from '@material-ui/core/styles';
import { useMediaQuery } from '@material-ui/core';
import { displayCentsAsDollars } from '../../utils';
import { clientNameService } from '../../services';

const useStyles = makeStyles((theme) => ({
  root: (props) => ({
    display: props.display,
    flexDirection: 'column',
    [theme.breakpoints.up('lg')]: {
      display: 'inline-block',
    },
  }),
  price: (props) => ({
    display: 'inline-flex',
    fontSize: props.priceSize,
    lineHeight: props.priceSize,
    color: theme.palette.primary.main,
    fontWeight: 700,
    [theme.breakpoints.up('sm')]: {
      fontSize: props.priceSize,
    },
  }),
  old: (props) => ({
    marginTop: props.marginTop,
    display: 'inline-flex',
    position: 'relative',
    fontSize: props.oldPriceSize,
    lineHeight: props.oldPriceSize,
    fontWeight: 600,
    color: 'var(--dark-gray)',
    marginRight: theme.spacing(2),
    textDecoration: 'line-through',
  }),
}));

export const ProductPrices = ({ productPrices, small, className, ...rest }) => {
  const isSmallResolution = useMediaQuery('@media(max-width: 600px)');

  const props = {
    priceSize: isSmallResolution && small ? '12px' : small ? '20px' : '29px',
    oldPriceSize: isSmallResolution && small ? '10px' : small ? '16px' : '24px',
    display: small ? 'flex' : 'inline-block',
    marginTop: isSmallResolution && small ? '-10px' : small ? '-15px' : '0',
  };
  const classes = useStyles(props);

  if (clientNameService.isWholesaleClient) {
    if (Number.isInteger(productPrices.wholesaleSale)) {
      return (
        <div className={clsx(classes.root, { [className]: Boolean(className) })} {...rest}>
          {productPrices.wholesale && (
            <span className={classes.old}>{displayCentsAsDollars(productPrices.wholesale)}</span>
          )}
          {productPrices.wholesaleSale && (
            <span className={classes.price}>
              {displayCentsAsDollars(productPrices.wholesaleSale)}
            </span>
          )}
        </div>
      );
    }

    return (
      <div className={clsx(classes.root, { [className]: Boolean(className) })} {...rest}>
        <span className={classes.price}>{displayCentsAsDollars(productPrices.wholesale)}</span>
      </div>
    );
  }

  if (Number.isInteger(productPrices.retailSale)) {
    return (
      <div className={clsx(classes.root, { [className]: Boolean(className) })} {...rest}>
        {productPrices.retail && (
          <span className={classes.old}>{displayCentsAsDollars(productPrices.retail)}</span>
        )}
        {productPrices.retailSale && (
          <span className={classes.price}>{displayCentsAsDollars(productPrices.retailSale)}</span>
        )}
      </div>
    );
  }

  return (
    <div className={clsx(classes.root, { [className]: Boolean(className) })} {...rest}>
      <span className={classes.price}>{displayCentsAsDollars(productPrices.retail)}</span>
    </div>
  );
};
