import React from 'react';
import { displayCentsAsDollars, getItemsLabel } from '../../utils';
import { Spinner } from '../spinner';
import { Button } from '../button';

export const ShoppingCartSubtotal = ({
  totalAmount,
  totalSum,
  isPrivacyPolicyAccepted,
  areErrorsPresent,
  isLoading,
  onClick,
  buttonText,
  idButton,
}) => {
  const privacyAccepted = isPrivacyPolicyAccepted ?? true;

  return (
    <div className="flex h-64 w-full justify-center md:w-1/3 md:min-w-80 md:justify-end">
      <div className="inline-block w-full px-5 py-10 text-center text-xl font-bold text-black shadow-rounded lg:w-11/12">
        <div>Subtotal</div>
        <div className="text-sm font-semibold text-gray-500">{`${totalAmount} ${getItemsLabel(totalAmount)}`}</div>
        <div className="my-6 text-4xl leading-[48px]">{displayCentsAsDollars(totalSum)}</div>
        <div className="relative flex justify-center">
          {totalAmount > 0 && (
            <Button
              id={idButton}
              variant="contained"
              disabled={!totalAmount || !privacyAccepted || areErrorsPresent}
              onClick={() => !isLoading && onClick()}
            >
              {buttonText}
            </Button>
          )}
          {isLoading && (
            <Spinner className="bg-white/80 absolute inset-0 flex items-center justify-center" />
          )}
        </div>
      </div>
    </div>
  );
};
