import { PRICE_TYPES } from '../constants';
import { getPriceByType } from './get-price-by-type';

export const getProductPrices = (productData) => {
  const retail = getPriceByType(productData?.prices, PRICE_TYPES.retail);
  const retailSale = getPriceByType(productData?.prices, PRICE_TYPES.retailSale);
  const wholesale = getPriceByType(productData?.prices, PRICE_TYPES.wholesale);
  const wholesaleSale = getPriceByType(productData?.prices, PRICE_TYPES.wholesaleSale);

  return {
    retail: retail?.value ?? null,
    retailSale: retailSale?.value ?? null,
    wholesale: wholesale?.value ?? null,
    wholesaleSale: wholesaleSale?.value ?? null,
  };
};
