import { useEffect } from 'react';
import { useLocation, useNavigate } from 'react-router-dom';
import { useCreateSessionWithToken, useQueryString, useWholesaleGenericMe } from '../../hooks';
import { clientNameService, sessionCacheStorage, slugService } from '../../services';
import { CLIENT_NAME, ROUTING_CONFIG } from '../../constants';

export const WholesaleSessionPage = () => {
  const { search } = useLocation();
  const { token } = useQueryString(search);
  const { createWholesaleGenericSession } = useCreateSessionWithToken();
  const { fetchWholeSaleMeAccount } = useWholesaleGenericMe();
  const navigate = useNavigate();

  useEffect(() => {
    const processToken = async (token) => {
      if (token) {
        const authToken = await createWholesaleGenericSession(token);
        const data = await fetchWholeSaleMeAccount(authToken);
        slugService.slug = data?.storefrontSlug;
        clientNameService.clientName = CLIENT_NAME.wholesale;
        sessionCacheStorage.setToken(authToken);

        navigate(ROUTING_CONFIG.home.replace(':storeFrontSlug', data?.storefrontSlug), {
          replace: true,
        });
        window.location.reload();
      }
    };
    processToken(token);
  }, []);

  return null;
};
