import React from 'react';
import { WholesaleLoginTemplate } from './wholesale-login-template';
import { WholeSaleLoginForm } from './wholesale-login-form';
import { useSendLoginViaEmail } from '../../hooks';
import { Spinner } from '../../components';
import { storageService } from '../../services';
import { useNavigate } from 'react-router-dom';

export const WholesaleLoginPage = () => {
  const { loading, sendLoginViaEmail } = useSendLoginViaEmail();
  const navigate = useNavigate();

  const onSubmit = async ({ email }) => {
    const resp = await sendLoginViaEmail({ email });

    if (resp) {
      storageService.setItem('wholesale_email', email);
      navigate('/wholesale-login-sent');
    }
  };

  if (loading) {
    return <Spinner />;
  }

  return (
    <WholesaleLoginTemplate>
      <WholeSaleLoginForm onSubmit={onSubmit} />
    </WholesaleLoginTemplate>
  );
};
