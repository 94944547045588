import { useContext, useMemo } from 'react';
import { useQuery } from '@apollo/client';
import { BRAND_TOP_SELLING_PRODUCTS } from '../queries';
import { clientNameService } from '../services';
import { Context } from '../store';
import { getStateByZipCode } from '../utils';

export const useBrandTopSellingProducts = (slug) => {
  const { state } = useContext(Context);
  const zipCode = state?.zipCode ?? '';
  const stateCode = getStateByZipCode(zipCode);
  const { loading, error, data } = useQuery(BRAND_TOP_SELLING_PRODUCTS, {
    variables: {
      filter: {
        brandSlugEq: slug,
        ...(zipCode && !clientNameService.isWholesaleClient
          ? {
              uidOmitsItemRestrictionsIn: [zipCode, stateCode?.shortName],
              brandUidOmitsBrandRestrictionsIn: [zipCode, stateCode?.shortName],
            }
          : {}),
      },
    },
    context: {
      clientName: clientNameService.clientName,
    },
  });

  const topSellingBrandProducts = useMemo(() => {
    if (data?.itemsTopSelling?.nodes?.length) {
      return data?.itemsTopSelling?.nodes;
    }

    return null;
  }, [data]);

  return {
    loading,
    error,
    topSellingBrandProducts,
  };
};
