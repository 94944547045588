import { getProductPrices } from './getProductPrices';

export const getShoppingCartSubTotal = (cartItems) =>
  cartItems.reduce((acc, item) => {
    const itemPrices = getProductPrices(item?.data);
    const relevantPrice = Number.isInteger(itemPrices.retailSale)
      ? itemPrices.retailSale
      : itemPrices.retail;

    return acc + item?.amount * relevantPrice;
  }, 0);
