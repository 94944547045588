import React, { useEffect, useState } from 'react';
import { WholesaleLoginTemplate } from './wholesale-login-template';
import { WholesaleOtpForm } from './wholesale-otp-form';
import {
  useCreateSessionWithOneTimeCode,
  useSendLoginViaEmail,
  useWholesaleGenericMe,
} from '../../hooks';
import { AuthFormFooter, Spinner } from '../../components';
import { Link, useNavigate } from 'react-router-dom';
import { CLIENT_NAME, ROUTING_CONFIG } from '../../constants';
import { CheckMarkIcon, ChevronRightIcon } from '../../components/icons/Icons';
import {
  clientNameService,
  sessionCacheStorage,
  slugService,
  storageService,
} from '../../services';

export const WholesaleLoginSentPage = () => {
  const [email, setEmail] = useState('');
  const { fetchWholeSaleMeAccount } = useWholesaleGenericMe();
  const { loading: resendLoading, sendLoginViaEmail } = useSendLoginViaEmail();
  const navigate = useNavigate();

  const { loading, createWholesaleGenericSession } = useCreateSessionWithOneTimeCode();

  useEffect(() => {
    const currentEmail = storageService.getItem('wholesale_email');
    setEmail(currentEmail);
  }, []);

  const submitHandler = async (otpCode) => {
    const token = await createWholesaleGenericSession(email, otpCode);
    if (token) {
      const data = await fetchWholeSaleMeAccount(token);
      slugService.slug = data?.storefrontSlug;
      clientNameService.clientName = CLIENT_NAME.wholesale;
      sessionCacheStorage.setToken(token);
      navigate(ROUTING_CONFIG.home.replace(':storeFrontSlug', data?.storefrontSlug), {
        replace: true,
      });
      window.location.reload();
    }
  };

  const clickResendEmail = async () => {
    await sendLoginViaEmail({ email });
  };
  return (
    <WholesaleLoginTemplate>
      <div className="shadow-block relative mx-4 flex max-w-lg flex-col items-center justify-center bg-white px-4 pb-10 pt-14 sm:px-10 sm:pb-12 sm:pt-16">
        {(loading || resendLoading) && <Spinner />}

        <Link
          to={ROUTING_CONFIG.wholesaleLogin}
          className="text-gray-middle absolute left-5 top-7 inline-flex items-center sm:left-12"
        >
          <ChevronRightIcon className="h-3 rotate-180" />
          <span className="text-r0.625 ml-1 font-medium">Back</span>
        </Link>

        <div className="mb-7 pb-px">
          <CheckMarkIcon className="h-12 w-12" />
        </div>

        <p className="mb-6 pb-0.5 text-center text-sm font-medium leading-6 text-gray-dark">
          We’ve just sent a letter with the Passcode to{' '}
          <span className="font-semibold text-black">{email}</span> email if it exists in our
          system. Please check your inbox and follow instructions to continue.
        </p>

        <p className="mb-4 pb-0.5 text-center text-xs font-semibold leading-6 text-gray-dark md:text-sm">
          Please enter a 6-digit code from the Email
        </p>

        <WholesaleOtpForm submitHandler={submitHandler} />

        <AuthFormFooter
          description="Didn’t get a link or a code? "
          labelButton="Click to Resend"
          onChangeState={clickResendEmail}
          buttonClassName="!text-blue-500"
        />
      </div>
    </WholesaleLoginTemplate>
  );
};
