export const BUTTON_COLORS_TYPES = {
  primary: 'primary',
  default: 'default',
  white: 'white',
  blue: 'blue',
};

export const BUTTON_SIZES_TYPES = {
  medium: 'medium',
  action: 'action',
  large: 'large',
};
