import React, { useState, useEffect, useRef } from 'react';
import { ExternalLinkIcon, MobileDropdown } from '../icons/Icons';
import { ConsoleAccessRoutes, ROUTING_CONFIG } from '../../constants';
import { useCreateConsoleAuthLink } from '../../hooks';
import { DesktopDropdown } from '../icons/Icons';

const RetailManagementRoutes = () => {
  const [isOpen, setIsOpen] = useState(false);
  const dropdownRef = useRef(null);

  const handleCreateConsoleAuthLink = useCreateConsoleAuthLink();

  useEffect(() => {
    function handleClickOutside(event) {
      if (dropdownRef.current && !dropdownRef.current.contains(event.target)) {
        setIsOpen(false);
      }
    }

    document.addEventListener('mousedown', handleClickOutside);
    return () => {
      document.removeEventListener('mousedown', handleClickOutside);
    };
  }, []);

  const createAuthUrl = async (type) => {
    try {
      const payload = {
        landingUrl: `${process.env.REACT_APP_CONSOLE_URI}${ROUTING_CONFIG?.consoleSession}?type=${type}`,
      };
      const response = await handleCreateConsoleAuthLink(payload);
      if (response && response.data) {
        window.location.href = response.data?.createConsoleAccessUrl?.url;
      }
    } catch (error) {
      console.log('error', error);
    }
  };

  return (
    <div
      className="relative flex flex-col justify-center text-center lg:inline-block"
      ref={dropdownRef}
    >
      <button
        onClick={() => setIsOpen((prevVal) => !prevVal)}
        className="flex w-full items-center justify-center gap-2 rounded-md border-b bg-transparent p-4 px-4 text-center text-base font-semibold uppercase text-black lg:border-b-0 lg:p-0 lg:text-sm lg:normal-case lg:text-gray-light"
      >
        Retail Management
        <span>
          <MobileDropdown />
          <DesktopDropdown />
        </span>
      </button>
      {isOpen && (
        <div className="relative right-0 z-10 mt-2 w-full rounded-md bg-white px-4 shadow-lg lg:absolute lg:w-48 lg:px-0">
          <ul className="py-2">
            {ConsoleAccessRoutes.map((item) => (
              <li key={item.name}>
                <span
                  onClick={() => createAuthUrl(item.type)}
                  className="flex cursor-pointer items-center justify-center px-4 py-2 text-base font-semibold text-[#232429] hover:bg-gray-100 lg:justify-between lg:text-xs"
                >
                  {item.name}
                  <ExternalLinkIcon />
                </span>
              </li>
            ))}
          </ul>
        </div>
      )}
    </div>
  );
};

export default RetailManagementRoutes;
