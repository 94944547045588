import React, { useEffect, useRef, useState } from 'react';
import { Spinner } from '../../components';

export const WholesaleOtpForm = ({ submitHandler, isLoading }) => {
  const [otpCode, setOtp] = useState('');
  const ref = useRef();

  useEffect(() => {
    ref.current.focus();
  }, []);

  useEffect(() => {
    if (otpCode.length === 6) {
      submitHandler(otpCode);
    }
  }, [otpCode]);

  const handleChange = (e) => {
    const value = e.target.value.replace(/\D/g, '');
    setOtp(value);
  };

  return (
    <div className="h-full items-center justify-center p-4">
      {isLoading && <Spinner />}
      <input
        ref={ref}
        type="text"
        maxLength="6"
        value={otpCode}
        onFocus={(e) => e.target.scrollTo(0, 0)}
        inputMode="numeric"
        onChange={handleChange}
        className="tracking-huge h-16 w-full rounded-lg border border-black bg-white px-4 pl-6 text-center text-2xl font-bold text-black caret-transparent focus:outline-none md:text-3xl"
      />
    </div>
  );
};
