import { useLazyQuery } from '@apollo/client';
import { getProductImage, getCategoryName } from '../utils';
import { IMAGE_SIZE_VALUES } from '../constants';
import { SHOPPING_CART_SUMMARY } from '../queries';
import { clientNameService } from '../services';
import { useShowToast } from './useShowToast';

const totalItems = (cartItems) => cartItems?.reduce((acc, cartItem) => acc + cartItem?.qty, 0);

const prepareCartItem = ({ qty, currentPrice, item }) => ({
  qty,
  currentPrice,
  item: {
    uid: item.uid,
    name: item.name,
    sku: item.sku,
    brand: item.brand.name,
    category: getCategoryName(item),
    image: getProductImage(item, IMAGE_SIZE_VALUES.small),
  },
});

const groupShippings = (cartShippings) =>
  cartShippings?.reduce((acc, shipping) => {
    acc[shipping?.supplier?.uid] = shipping;

    return acc;
  }, {});

const groupCartData = (cartItems, cartShippings) => {
  const groupedShippings = groupShippings(cartShippings);

  const groupedData = cartItems?.reduce((acc, cartItem) => {
    const supplierUid = cartItem?.item?.supplier?.uid;

    if (!acc[supplierUid]) {
      const shipping = groupedShippings[supplierUid];

      acc[supplierUid] = {
        shippingPrice: shipping?.price || 0,
        supplier: shipping?.supplier || null,
        cartItems: [],
      };
    }
    acc[supplierUid].cartItems.push(prepareCartItem(cartItem));

    return acc;
  }, {});

  return groupedData && Object.values(groupedData);
};

const prepareInput = (inputCartItems) =>
  inputCartItems?.map(({ uid: itemUid, amount: qty }) => ({ itemUid, qty }));

export const useShoppingCartSummary = () => {
  const [runQuery, { data, error, loading }] = useLazyQuery(SHOPPING_CART_SUMMARY, {
    context: {
      clientName: clientNameService.clientName,
    },
  });

  const runShoppingCartSummaryQuery = async (inputCartItems, couponCodeUid) => {
    const res = await runQuery({
      variables: { cartItems: prepareInput(inputCartItems), couponCodeUid },
    });
    if (res?.error) {
      showError(res?.error?.message);
      await runQuery({ variables: { cartItems: prepareInput(inputCartItems) } });
    }
  };
  const { showError } = useShowToast();

  const summary = data?.shoppingCartSummary;
  const details = groupCartData(summary?.cartItems, summary?.cartShippings);

  const summaryData = {
    details,
    total: summary?.total,
    subtotal: summary?.subtotal,
    totalShipping: summary?.totalShipping,
    itemsCount: totalItems(summary?.cartItems),
    shippingsCount: summary?.cartShippings?.length,
    discountPercentage: summary?.discountPercentage,
    totalDiscount: summary?.totalDiscount,
  };

  return {
    loading,
    error,
    summaryData,
    runShoppingCartSummaryQuery,
  };
};
