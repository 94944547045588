import { useMutation } from '@apollo/client';
import { useState } from 'react';
import { useErrors } from './useErrors';
import { clientNameService } from '../services';
import { SEND_LOGIN_VIA_EMAIL } from '../mutations/sendLoginLinkViaEmailMutation';
import { getMarketplaceHeader } from '../utils';

export const useSendLoginViaEmail = () => {
  const [sendLoginViaEmailMutation] = useMutation(SEND_LOGIN_VIA_EMAIL);
  const { errorsHandler } = useErrors();
  const [loading, setLoading] = useState(false);

  const sendLoginViaEmail = async ({ email }) => {
    try {
      setLoading(true);
      const response = await sendLoginViaEmailMutation({
        variables: {
          email,
          landingUrl: `${window.location.origin}/wholesale-session/`,
        },

        context: {
          clientName: clientNameService.loginClientName(true),
          headers: {
            ...getMarketplaceHeader(),
          },
        },
      });

      setLoading(false);

      return response?.data?.sendLoginLinkViaEmail ?? null;
    } catch (error) {
      errorsHandler(error);
      setLoading(false);
      return null;
    }
  };
  return {
    loading,
    sendLoginViaEmail,
  };
};
