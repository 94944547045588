import React from 'react';
import { useForm, Controller } from 'react-hook-form';
import { yupResolver } from '@hookform/resolvers/yup';
import { AuthFormFooter, InputTextField } from '../../components';
import { Button } from '../../components';
import * as yup from 'yup';
import { BUTTON_COLORS_TYPES } from '../../constants';

const formSchema = yup.object().shape({
  email: yup.string().email('Invalid email').required('Required'),
});

export const WholeSaleLoginForm = ({ onSubmit }) => {
  const {
    handleSubmit,
    control,
    formState: { errors, isValid },
  } = useForm({
    defaultValues: {
      email: '',
    },
    resolver: yupResolver(formSchema),
    mode: 'onChange',
  });

  const redirectToConsole = () => {
    const hostname = window?.location?.hostname?.split('.');
    const marketplace =
      process.env.NODE_ENV === 'development'
        ? process.env.REACT_APP_LOCAL_MARKETPLACE_SLUG
        : hostname[0];
    window.location.href = `${process.env.REACT_APP_CONSOLE_URI}/landing/${marketplace}`;
  };

  return (
    <div className="h-80 w-96 bg-white px-6 py-10 shadow-xl">
      <form
        onSubmit={handleSubmit(onSubmit)}
        className="flex w-full max-w-md flex-col items-center justify-between"
        noValidate
      >
        <div className="text-center">
          <h1 className="text-xl font-bold text-gray-800">Welcome to SalonHQ!</h1>
          <p className="mt-6 text-sm text-gray-600">Please Sign in to your Professional Account</p>
        </div>

        <div className="mt-10 w-full space-y-4">
          <Controller
            name="email"
            control={control}
            render={({ field }) => (
              <InputTextField
                fullWidth
                variant="outlined"
                error={!!errors.email}
                helperText={errors.email?.message}
                label="Email"
                InputLabelProps={{
                  required: true,
                  classes: {
                    asterisk: 'text-red-500',
                  },
                }}
                {...field}
              />
            )}
          />
        </div>

        <Button
          type="submit"
          disabled={!isValid}
          className="mt-2 w-full text-white"
          color={BUTTON_COLORS_TYPES.blue}
        >
          Send magic link
        </Button>
        <AuthFormFooter
          description="Don't have an account?"
          labelButton="Sign Up"
          buttonClassName="!text-blue-500"
          onChangeState={redirectToConsole}
        />
      </form>
    </div>
  );
};
