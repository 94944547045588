import React, { useContext, useMemo } from 'react';
import { makeStyles, withStyles } from '@material-ui/core/styles';
import { Toolbar, Grid, Container, TextField, Link } from '@material-ui/core';
import { Context } from '../../store';
import { OUTER_LINKS } from '../../constants';

const stylesSubscribeField = (theme) => ({
  root: {
    width: 'calc(100% - 120px)',
    [theme.breakpoints.up('sm')]: {
      width: '260px',
    },
    '& .MuiOutlinedInput-root': {
      paddingLeft: '20px',
      borderRadius: '18px 0 0 18px',
      '& input': {
        padding: '8px 20px 8px 0',
        fontSize: '12px',
        fontStyle: 'italic',
        height: '20px',
        lineHeight: '20px',
        color: 'var(--gray)',
      },
      '&:hover input': {
        opacity: 0.5,
      },
      '&.Mui-focused input': {
        opacity: 1,
      },
      '& fieldset': {
        borderColor: 'var(--light-gray)',
      },
      '&:hover fieldset': {
        borderColor: 'var(--light-gray)',
        opacity: 0.5,
      },
      '&.Mui-focused fieldset': {
        borderColor: 'var(--light-gray)',
        borderWidth: '1px',
        opacity: 1,
      },
    },
  },
});

const useStyles = makeStyles((theme) => ({
  footer: {
    boxShadow: '0px 5px 30px #0000000D',
  },
  top: {
    minHeight: '170px',
    padding: 0,
    background: 'var(--white)',
    alignItems: 'center',
  },
  bottom: {
    minHeight: '45px',
    padding: 0,
    background: theme.palette.secondary.main,
  },
  bottomText: {
    color: 'var(--white)',
    textTransform: 'uppercase',
    fontSize: '10px',
    letterSpacing: '0.5px',
    padding: '15px 0px  5px 0px',
    justifyContent: 'space-between',
    [theme.breakpoints.up('sm')]: {
      padding: '0',
    },
  },
  menu: {
    display: 'flex',
    alignItems: 'center',
    justifyContent: 'flex-start',
    padding: '0 0px  15px 0px',
    [theme.breakpoints.up('sm')]: {
      padding: '0',
      justifyContent: 'flex-end',
    },
    '& > * + *': {
      marginLeft: '25px',
      [theme.breakpoints.up('sm')]: {
        marginLeft: '50px',
      },
    },
  },
  menuItem: {
    textDecoration: 'underline',
    '&:hover': {
      textDecoration: 'none',
    },
  },
  selected: {
    textDecoration: 'none',
  },
  subscription: {
    marginBottom: '20px',
    '& button': {
      color: 'var(--white)',
      background: theme.palette.primary.main,
      borderRadius: '0 18px 18px 0',
      boxShadow: 'none',
      fontSize: '12px',
      fontWeight: 600,
      letterSpacing: '1.2px',
      lineHeight: '24px',
      width: '120px',
      height: '36px',
      [theme.breakpoints.up('sm')]: {
        '&:hover': {
          boxShadow: 'none',
          background: 'var(--gray)',
        },
      },
    },
    [theme.breakpoints.up('sm')]: {
      marginBottom: '0',
    },
  },
  social: {
    display: 'flex',
    alignItems: 'center',
    justifyContent: 'flex-start',
    marginBottom: theme.spacing(3),
    marginLeft: '-7px',
    marginTop: theme.spacing(-2),
    [theme.breakpoints.up('sm')]: {
      justifyContent: 'center',
      margin: '0',
    },
    '& > * + *': {
      marginLeft: theme.spacing(2),
    },
  },
  icon: {
    width: '35px',
    height: '36px',
    color: theme.palette.primary.main,
    '&:hover': {
      opacity: 0.5,
    },
  },
  title: {
    color: 'var(--dark-gray)',
    fontSize: '13px',
    fontWeight: 700,
    textTransform: 'uppercase',
    marginBottom: '15px',
    textAlign: 'left',
    [theme.breakpoints.up('sm')]: {
      alignItems: 'center',
      fontSize: '15px',
      textAlign: 'center',
      maxWidth: '370px',
    },
  },
  privacyLink: {
    fontSize: '10px',
    color: '#fff',
    textTransform: 'uppercase',
  },
}));

export const SubscribeField = withStyles(stylesSubscribeField)(({ classes }) => (
  <TextField
    className={classes.root}
    disabled
    id="subscribe"
    variant="outlined"
    placeholder="Your Email"
  />
));

export const Footer = () => {
  const classes = useStyles();
  const {
    state: { config },
  } = useContext(Context);

  const currentYear = useMemo(() => new Date().getFullYear(), []);

  return (
    <footer className={classes.footer}>
      {/* TODO will be need late */}
      {/* <Toolbar className={classes.top}> */}
      {/*  <Container> */}
      {/*    <Grid container spacing={0}> */}
      {/*      <Grid item xs={12} sm={9}> */}
      {/*        <h3 className={classes.title}>Receive our latest news & updates!</h3> */}
      {/*        <div className={classes.subscription}> */}
      {/*          <SubscribeField/> */}
      {/*          <Button variant="contained">Submit</Button> */}
      {/*        </div> */}
      {/*      </Grid> */}
      {/*      <Grid item xs={12} sm={3}> */}
      {/*        <h3 className={classes.title}>Learn more:</h3> */}
      {/*        <div className={classes.social}> */}
      {/*          <a href="https://www.instagram.com/" target="_blank" rel="noreferrer"><InstagramIcon */}
      {/*            className={classes.icon}/></a> */}
      {/*          <a href="https://www.facebook.com/" target="_blank" rel="noreferrer"><FacebookIcon */}
      {/*            className={classes.icon}/></a> */}
      {/*          <a href="https://www.youtube.com/" target="_blank" rel="noreferrer"><YoutubeIcon */}
      {/*            className={classes.icon}/></a> */}
      {/*        </div> */}
      {/*      </Grid> */}
      {/*    </Grid> */}
      {/*  </Container> */}
      {/* </Toolbar> */}
      <Toolbar className={classes.bottom}>
        <Container>
          <Grid container spacing={0}>
            <Grid className={classes.bottomText} item xs={12} container>
              <span>
                @ {currentYear} {config?.general?.name}. Powered by SalonHQ
              </span>
              <Link
                className={classes.privacyLink}
                target="__blank"
                href={OUTER_LINKS.privacy_policy_url}
                underline="always"
              >
                SALONHQ PRIVACY POLICY
              </Link>
            </Grid>
            {/* TODO will be need late */}
            {/* <Grid className={classes.menu} item xs={12} sm={7}> */}
            {/*  <NavLink to="/site-map" className={`${classes.bottomText} ${classes.menuItem}`} */}
            {/*           activeClassName={classes.selected}> */}
            {/*    Site Map */}
            {/*  </NavLink> */}
            {/*  <NavLink to="/privacy-policy" className={`${classes.bottomText} ${classes.menuItem}`} */}
            {/*           activeClassName={classes.selected}> */}
            {/*    Privacy Policy */}
            {/*  </NavLink> */}
            {/*  <NavLink to="/terms-and-conditions" className={`${classes.bottomText} ${classes.menuItem}`} */}
            {/*           activeClassName={classes.selected}> */}
            {/*    Terms & Conditions */}
            {/*  </NavLink> */}
            {/* </Grid> */}
          </Grid>
        </Container>
      </Toolbar>
    </footer>
  );
};
