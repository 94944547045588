import React from 'react';
import { makeStyles } from '@material-ui/core/styles';
import clsx from 'clsx';

const useStyles = makeStyles((theme) => ({
  formFooter: {
    display: 'flex',
    alignItems: 'center',
    justifyContent: 'center',
    margin: '20px 0 0',
  },
  footerDesc: {
    color: 'var(--dark-gray)',
    fontSize: '10px',
    lineHeight: '16px',
    fontWeight: 'normal',
    margin: 0,
  },
  changeStateButton: {
    border: 'none',
    background: 'transparent',
    padding: 0,
    color: theme.palette.primary.main,
    marginLeft: '4px',
    fontSize: '10px',
    fontWeight: 600,
    textDecoration: 'underline',
    cursor: 'pointer',
  },
}));

export const AuthFormFooter = ({ description, onChangeState, labelButton, buttonClassName }) => {
  const classes = useStyles();

  return (
    <div className={classes.formFooter}>
      <p className={classes.footerDesc}>{description}</p>
      <button
        className={clsx(classes.changeStateButton, buttonClassName)}
        type="button"
        onClick={() => onChangeState?.()}
      >
        {labelButton}
      </button>
    </div>
  );
};
