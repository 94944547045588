import React from 'react';
import { CompanyLogo } from '../../components';
import { AuthLinks } from '../../components';

export const WholesaleLoginTemplate = ({ children }) => {
  return (
    <div className="bg-auth flex min-h-screen bg-gray-100">
      <div className="flex w-full justify-center">
        <div className="relative flex h-full max-w-lg flex-col items-center justify-between px-6 lg:px-0">
          <div className="flex items-center py-12 pt-16 lg:block lg:flex-none">
            <CompanyLogo />
          </div>
          {children}
          <AuthLinks />
        </div>
      </div>
    </div>
  );
};
