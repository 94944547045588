import React from 'react';
import { Link } from 'react-router-dom';

export const AuthLinks = () => {
  return (
    <div className="flex flex-col items-center space-y-2 py-8 text-sm font-medium text-blue-500 lg:flex-row lg:space-x-4 lg:space-y-0">
      <Link target="_blank" to="https://salonhq.co/privacy-policy/">
        SalonHQ Privacy Policy
      </Link>

      <div className="hidden text-gray-dark lg:block">|</div>

      <Link target="_blank" to="https://salonhq.co/terms-of-service-sms/">
        SalonHQ Messaging Terms Of Service
      </Link>
    </div>
  );
};
