import React, { useCallback, useContext, useEffect } from 'react';
import { useLocation } from 'react-router-dom';
import { makeStyles } from '@material-ui/core/styles';
import { AppBar } from '../app-bar';
import { Container } from '@material-ui/core';
import isEqual from 'lodash.isequal';
import { Footer } from '../footer';
import { Context } from '../../store';
import { ROUTING_CONFIG } from '../../constants';
import { debounce } from '../../utils';
import { Auth } from '../auth';
import { emailService } from '../../services';

import { useMeShoppingCart } from '../../hooks';

const useStyles = makeStyles((theme) => ({
  content: {
    position: 'relative',
    display: 'inline-flex',
    flexDirection: 'column',
    flexGrow: 1,
    paddingTop: theme.spacing(4),
    paddingBottom: theme.spacing(4),
    marginTop: ({ hasZipCode }) => (!hasZipCode ? '261px' : '173px'),
    [theme.breakpoints.up('sm')]: {
      marginTop: ({ hasZipCode }) => (!hasZipCode ? '220px' : '143px'),
      paddingTop: theme.spacing(10),
      paddingBottom: theme.spacing(10),
    },
  },
}));

export const MainLayout = ({ children }, props) => {
  const { state } = useContext(Context);
  const classes = useStyles({ hasZipCode: state?.zipCode });
  const [openAuth, setOpeningAuth] = React.useState(false);
  const { updateMeShoppingCart, removeMeShoppingCart } = useMeShoppingCart();
  const { pathname } = useLocation();

  const handleAuthClose = () => {
    setOpeningAuth(false);
    emailService.remove();
  };

  const handleUpdateShoppingCart = useCallback(
    debounce((cart) => {
      if (state.account && !isEqual(cart, state?.account?.shoppingCart)) {
        const cartItems = cart?.map((item) => ({
          itemUid: item?.uid,
          qty: item?.amount,
        }));

        if (cartItems?.length) {
          updateMeShoppingCart(cartItems);
        } else {
          removeMeShoppingCart();
        }
      }
    }, 5000),
    [],
  );

  useEffect(() => {
    if (state.initialized) {
      handleUpdateShoppingCart(state?.cart);
    }
  }, [state.cart]);

  if (
    pathname.includes(ROUTING_CONFIG.notFound) ||
    pathname.includes(ROUTING_CONFIG.wholesaleLogin) ||
    pathname.includes(ROUTING_CONFIG.wholesaleLoginSent) ||
    pathname.includes(ROUTING_CONFIG.wholesaleSession)
  ) {
    return <>{children}</>;
  }

  return (
    <>
      <div {...props}>
        <AppBar zipCode={state?.zipCode} />
      </div>
      <Container maxWidth="lg" className={classes.content}>
        {children}
      </Container>
      <Footer />
      {openAuth && <Auth onClose={handleAuthClose} />}
    </>
  );
};
