import React, { useContext } from 'react';
import { MobileHeader } from './mobile-header';
import { UpdateLocationButton } from '../update-location-button';
import { Auth } from '../auth';
import { LogoutButton } from '../logout-button';
import clsx from 'clsx';
import { NavLink } from 'react-router-dom';
import { Context } from '../../store';
import RetailManagementRoutes from './retail-management-routes';
import { clientNameService } from '../../services';

export const Drawer = ({ isMenuOpen, navRoutes, toggleMenu }) => {
  const { state } = useContext(Context);
  const { account } = state || {};

  const renderNavRoutes = () =>
    navRoutes.map((route) => (
      <NavLink to={route.url} onClick={toggleMenu} key={route.label} target={route.target}>
        <div
          className={clsx(
            'w-full p-4 text-center text-base font-semibold uppercase text-black',
            account ? 'border-b' : 'border-t',
          )}
        >
          {route.label}
        </div>
      </NavLink>
    ));

  return (
    <div className="lg:hidden">
      <div
        className={clsx(
          'fixed left-0 top-0 z-50 h-dvh w-full transform bg-white shadow-md transition-transform duration-300 ease-in-out',
          isMenuOpen ? 'translate-x-0' : '-translate-x-full',
        )}
      >
        <MobileHeader isMenuOpen={isMenuOpen} toggleMenu={toggleMenu} />

        <div>
          {account && (
            <div className="border-b border-t p-4">
              <Auth onClick={toggleMenu} />
            </div>
          )}

          <div className="items-center text-base">{renderNavRoutes()}</div>
          {clientNameService.isWholesaleClient && <RetailManagementRoutes />}
          {account ? (
            <div className="w-full p-4 text-center">
              <LogoutButton onClick={toggleMenu} />
            </div>
          ) : (
            <div className="fixed bottom-0 left-0 flex w-full justify-around border-t border-gray-light pb-5 pt-4 text-black">
              <div className="w-full border-r border-gray-light">
                <UpdateLocationButton onClick={toggleMenu} />
              </div>
              <div className="w-full">
                <Auth onClick={toggleMenu} />
              </div>
            </div>
          )}
        </div>
      </div>
    </div>
  );
};
