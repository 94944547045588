import { storageService } from './storage-service';
import { LOCAL_STORAGE_KEYS, ROUTING_CONFIG } from '../constants';
import { slugService } from './slug-service';

class SessionCacheService {
  _token;

  constructor() {
    this._initToken();
  }

  getStoreFrontSlug() {
    if (slugService.slug) return slugService.slug;

    const { pathname } = window.location;
    const splittedPath = pathname.split('/').filter((item) => item);

    if (splittedPath?.length && !pathname.includes(ROUTING_CONFIG.notFound)) {
      const [slug] = splittedPath;
      return slug;
    }
  }

  get token() {
    return this._token;
  }

  get hasToken() {
    return !!this._token;
  }

  setToken(token) {
    const slugName = this.getStoreFrontSlug();
    storageService.setItem(LOCAL_STORAGE_KEYS.token + slugName, token);
    this._updateServiceState(token);
  }

  removeToken() {
    const slugName = this.getStoreFrontSlug();
    storageService.removeItem(LOCAL_STORAGE_KEYS.token + slugName);
    this._updateServiceState();
  }

  _updateServiceState(token) {
    this._token = token;
  }

  _initToken() {
    const params = new URLSearchParams(window.location.hash?.replace('#', ''));
    const urlToken = params?.get('token');
    const slugName = this.getStoreFrontSlug();
    // remove after  01.03.2025

    const oldToken = storageService.getItem('token');
    if (oldToken) {
      storageService.removeItem('token');
    }

    if (urlToken) {
      this.setToken(urlToken);
      this._removeTokenFromHash();
      return;
    }

    this.setToken(storageService.getItem(LOCAL_STORAGE_KEYS.token + slugName));
  }

  _removeTokenFromHash() {
    window.location.hash = window.location.hash.replace(`token=${this.token}`, '');
  }
}

export const sessionCacheStorage = new SessionCacheService();
