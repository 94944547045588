import { useMutation } from '@apollo/client';
import { gql } from '@apollo/client/core';
import { clientNameService } from '../services';

const CREATE_CONSOLE_LINK = gql`
  mutation createConsoleAccessUrl($landingUrl: String!) {
    createConsoleAccessUrl(landingUrl: $landingUrl) {
      url
    }
  }
`;

export const useCreateConsoleAuthLink = () => {
  const [createConsoleAuthMutation] = useMutation(CREATE_CONSOLE_LINK);
  try {
    const handleCreateConsoleAuthLink = async (input) => {
      const response = await createConsoleAuthMutation({
        variables: input,
        context: {
          clientName: clientNameService.clientName,
        },
      });

      return response;
    };

    return handleCreateConsoleAuthLink;
  } catch (error) {
    console.error(error.graphQLErrors);
    return null;
  }
};
